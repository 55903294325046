import './App.css';
import NavbarComponent from './Components/NavbarComponent';
import HeroComponent from './Components/HeroComponent';
import AboutComponent from './Components/AboutComponent';
// import ServicesComponent from './Components/ServicesComponent';
import TestServicesComponent from './Components/TestServicesComponent';
// import PortfolioComponent from './Components/PortfolioComponent';
import TestPortfolioComponent from './Components/TestPortfolioComponent';
// import ContactComponent from './Components/ContactComponent';
import TestContactComponent from './Components/TestContactComponent';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import BGImage from './Images/BGImage.png'
import theme from './theme';


function App() {
  const theme = useTheme();
  // const backgroundColor = theme.palette.common.background;

  return (
    <ThemeProvider theme={theme}>
      <div style={{
        backgroundImage: {BGImage}, // Replace with your image path
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        backgroundPosition: 'center',
        minHeight: '100vh', // Ensures it covers the full view height
        minWidth: '100vw', // Ensures it covers the full view width
        overflowX: 'hidden', // Prevents horizontal scroll
      }}>
        <NavbarComponent />
        <HeroComponent />
        <AboutComponent />
        <TestServicesComponent />
        <TestPortfolioComponent />
        <TestContactComponent />
      </div>
    </ThemeProvider>
  );
}

export default App;
