import React from 'react';
import { Box, Button, Typography, Card, CardContent, useTheme, Grid } from '@mui/material';
import ContactGlyph from '../Icons/Contact Icon.png'

const TestContactComponent = () => {
  const theme = useTheme();
  const contactEmail = "contact@vyerosolutions.com"; // Replace with the actual contact email address

  const iconStyle = {
    height: '50px', // Adjust the size as needed
    marginBottom: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  }

  const ctaButtonStyle = {
    marginTop: theme.spacing(2),
    borderRadius: '20px',
    backgroundColor: theme.palette.secondary.main, // Use the theme primary color
    color: theme.palette.common.white,
    padding: theme.spacing(1, 4),
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.light, // Darken the button on hover
    }
  }
  
  return (
    <Box
      id="contact"
      sx={{
        py: theme.spacing(4),
        backgroundColor: theme.palette.background.default,
        color: theme.palette.common.white,
        minHeight: '50vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Grid item xs={12} sm={4} display="flex" justifyContent="center" alignItems="center">
  <Card sx={{
    bgcolor: 'rgba(255, 255, 255, 0.1)',
            backdropFilter: 'blur(10px)',
            border: '1px solid rgba(255, 255, 255, 0.3)',
            borderRadius: theme.shape.borderRadius,
            color: 'common.white',
            maxWidth: 600, // Optional max width
            alignItems: 'center',
            justifyContent: 'center',
  }}>
    <CardContent>
      <div style={{ textAlign: 'center' }}>
        <img src={ContactGlyph} alt="Icon 1" style={iconStyle} />
        <Typography variant="h3" gutterBottom align="center">
          Contact
        </Typography>
        <Typography variant="h6" gutterBottom align="center">
          Let's talk business... <br /> Hope to hear from you soon!
        </Typography>

        <Button variant="contained" style={ctaButtonStyle}>
          <Typography variant="h6" align="center">
            contact@vyerosolutions.com
          </Typography>
        </Button>
      </div>
    </CardContent>
  </Card>
</Grid>
    </Box>
  );
};

export default TestContactComponent;