import React, { useState } from 'react';
import { Box, Typography, Grid, List, ListItem, ListItemText, Paper, useTheme, Button } from '@mui/material';

const services = [
  {
    title: "Business Strategy Development",
    items: [
      { name: "Strategic Planning", description: "Develop long-term business plans and objectives aligned with the client's vision." },
      { name: "Market Entry Strategy", description: "Assist in entering new markets with market research and market entry plans." },
      { name: "Business Expansion", description: "Create strategies for scaling and expanding existing businesses." },
      { name: "Business Model Innovation", description: "Evaluate and innovate existing business models for greater efficiency." },
      { name: "SWOT Analysis", description: "Conduct a thorough analysis of strengths, weaknesses, opportunities, and threats." },
      { name: "Feasible Studies", description: "Assess the viability of new business ideas or ventures." },
    ]
  },
  {
    title: "Operational Efficiency Improvement",
    items: [
      { name: "Process Optimization", description: "Streamline internal processes to reduce waste and improve productivity." },
      { name: "Cost Reduction Strategies", description: "Identify areas where costs can be minimized without compromising quality." },
      { name: "Lean Management", description: "Implement lean principles to eliminate inefficiencies and improve resource utilization." },
      { name: "Workflow Automation", description: "Integrate technology solutions to automate repetitive tasks and workflows." },
      { name: "Quality Control", description: "Develop quality assurance and control processes to maintain product/service quality." },
      { name: "Performance Metrics", description: "Define key performance indicators (KPIs) for ongoing performance monitoring." },
    ]
  },
  {
    title: "Risk Management and Compliance",
    items: [
      { name: "Risk Assessment", description: "Identify and assess potential risks to the business, including financial, operational, and legal." },
      { name: "Compliance Audits", description: "Ensure adherence to industry regulations and standards." },
      { name: "Crisis Preparedness", description: "Develop crisis management plans and protocols." },
      { name: "Insurance Solutions", description: "Evaluate and recommend appropriate insurance coverage for risk mitigation." },
      { name: "Data Security", description: "Implement data security measures to protect sensitive information." },
      { name: "Contract Compliance", description: "Ensure contract terms and agreements are upheld." },
    ]
  },
  {
    title: "Supplier Relationship Management",
    items: [
      { name: "Fortify Networks", description: "Strengthen relationships with suppliers for better pricing and reliability." },
      { name: "Inventory Management", description: "Optimize inventory levels to minimize carrying costs and stockouts." },
      { name: "Demand Forecasting", description: "Improve accuracy in predicting product demand." },
      { name: "Logistics Optimization", description: "Enhance transportation and logistics processes for efficiency." },
      { name: "Sourcing Strategies", description: "Identify cost-effective sourcing options and strategies." },
      { name: "Supply Chain Visibility", description: "Implement tools and systems for real-time supply chain tracking." },
    ]
  },
  {
    title: "Technology Adoption and Digital Transformation",
    items: [
      { name: "Digital Strategy Development", description: "Create a roadmap for digital transformation initiatives." },
      { name: "Technology Assessment", description: "Evaluate and recommend technology solutions to meet business needs." },
      { name: "Cloud Integration", description: "Transition to cloud-based systems for scalability and flexibility." },
      { name: "Data Analytics", description: "Leverage data analytics for insights and decision-making." },
      { name: "Cybersecurity", description: "Ensure robust cybersecurity measures to protect digital assets." },
      { name: "Change Management", description: "Manage organizational changes associated with technology adoption." },
    ]
  },
  {
    title: "Financial Restructuring and Turnaround Consulting",
    items: [
      { name: "Financial Analysis", description: "Conduct a comprehensive analysis of financial statements and performance." },
      { name: "Debt Restructuring", description: "Negotiate debt terms and repayment plans with creditors." },
      { name: "Cost Reduction Planning", description: "Identify and implement cost-cutting measures." },
      { name: "Cash Flow Management", description: "Improve cash flow forecasting and management." },
      { name: "Restructuring Strategies", description: "Develop and execute strategies for business recovery." },
      { name: "Stakeholder Communication", description: "Communicate with stakeholders during periods of financial distress." },
    ]
  },
];

const CTASection = () => {
  const scrollToContact = () => {
    // Function to scroll to the contact section
    // Replace 'contact-section-id' with the actual ID of your contact section
    document.getElementById('contact').scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 8, // Or any other value that suits your design
      // Add any other styles you need here
    }}>
      <Button
        variant="contained"
        color="success" // Adjust the color to match your design
        onClick={scrollToContact}
        sx={{ borderRadius: 50, textTransform: 'none' }} // Example of styling; adjust as needed
      >
        Let's Talk!
      </Button>
      <Typography
        variant="body1"
        sx={{
          marginLeft: 2, // Or any other value for spacing
          color: 'white' // Adjust the color to match your design
        }}
      >
        It's time to scale.
      </Typography>
    </Box>
  );
};

const TestServicesComponent = () => {
  const theme = useTheme();
  const [activeService, setActiveService] = useState(services[0]);

  const handleListItemClick = (service) => {
    setActiveService(service);
  };

  return (
    <Box
      id="services"
      sx={{
        py: 4,
        backgroundColor: 'background.default',
        color: 'common.white', // Ensures text is white
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center', // Centers children horizontally
      }}
    >
      <Typography variant="h3" gutterBottom align="center" color="common.white">
        Services
      </Typography>
      <Grid container spacing={4} justifyContent="center" alignItems="stretch">
        <Grid item xs={12} sm={4} md={3}>
          <List component="nav">
            {services.map((service, index) => (
              <ListItem 
                button 
                key={index} 
                selected={activeService.title === service.title}
                onClick={() => handleListItemClick(service)}
                sx={{
                  color: 'common.white',
                  bgcolor: activeService.title === service.title ? 'success.main' : 'transparent',
                  '&:hover': {
                    bgcolor: 'success.light',
                    '.MuiListItemText-primary': { // Targets the text specifically
                      color: 'common.white',
                    },
                  },
                  '&.Mui-selected': {
                    color: theme.palette.common.white,
                    bgcolor: 'success.main',
                    '.MuiListItemText-primary': { // Ensures the text color remains white when selected
                      color: 'common.white',
                    },
                  },
                }}
              >
                <ListItemText primary={service.title} />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={12} sm={8} md={9}>
          <Grid container spacing={2}>
            {activeService.items.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Paper 
                  sx={{
                    p: 2, 
                    display: 'flex', 
                    flexDirection: 'column', 
                    justifyContent: 'space-between', 
                    height: '100%',
                    background: 'rgba(255, 255, 255, 0.1)', // Translucent background
                    backdropFilter: 'blur(10px)', // Apply a blur effect to the background
                    border: '1px solid', // Optional border
                    borderColor: 'rgba(255, 255, 255, 0.3)', // Border color with transparency
                    borderRadius: theme.shape.borderRadius, // Use the theme's border radius
                  }}
                >
                  <Typography variant="h6" component="h3" color="common.white">{item.name}</Typography>
                  <Typography paragraph color="common.white">{item.description}</Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      
      <Box>
      {/* ... existing elements */}
      
      {/* CTA Section */}
      <CTASection />
    </Box>
    
    </Box>
    
    
    
    
  );
};

export default TestServicesComponent;
