import React from 'react';
import { Box, Button, Typography, Grid, ListItemButton, ListItemText } from '@mui/material';
import theme from '../theme.js'; // Ensure this path is correct
import AboutIcon from '../Icons/AboutIcon.png'
import Glyph from '../Icons/Glyph.png'
// Import other icons similarly

const AboutSection = () => {
  const aboutStyle = {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.background.default, // Use the theme background color
    padding: theme.spacing(4), // Use theme spacing for padding
    textAlign: 'center',
    justifyContent: 'center'
  };

  const iconStyle = {
    height: '50px', // Adjust the size as needed
    marginBottom: theme.spacing(1),
  };

  const ctaButtonStyle = {
    marginTop: theme.spacing(2),
    borderRadius: '20px',
    backgroundColor: theme.palette.secondary.main, // Use the theme primary color
    color: theme.palette.common.white,
    padding: theme.spacing(1, 4),
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.light, // Darken the button on hover
    },
  };

  return (
    <Box style={aboutStyle} id="about">
    <img src={AboutIcon} alt="Icon 1" style={iconStyle} />
      <Typography variant="h3" gutterBottom>
        About Me
      </Typography>
      <Typography variant="h8" gutterBottom>
      Vladyslav is a certified Logistics Operations Manager with experience in Rail Transportation, <br /> Customer Relations, and Business Development.
      </Typography>

      <Box style={{ display: 'flex', alignItems: 'center', ...aboutStyle }}>
      <div style={{ marginRight: '30px' }}>
        <Button variant="contained" style={ctaButtonStyle}>
          Find Out More
        </Button>
      </div>
      <Typography variant="h8" style={{marginTop: '12px'}}>
        Reach out and book a one-to-one with me.
      </Typography>
    </Box>
      
      <Grid container spacing={4} justifyContent={'center'} padding={12}>
        <Grid item xs={12} sm={6} md={4} textAlign={'left'} padding={4}>
        <Box display="flex" alignItems="center">
          <img src={Glyph} alt="Icon 1" style={iconStyle} />
          
          <Typography variant="h6" paddingLeft={2} sx={{ fontWeight: 'bold' }}>
            Education
          </Typography>
        </Box>
          
          <Typography paragraph sx={{ fontWeight: 'bold' }}>
            Bachelor’s Degree in International Business
          <Typography caption sx={{ fontWeight: 'light' }}>
            Brunel University London (2019 - 2022)
          </Typography>
          </Typography>
          
          <Typography paragraph>
            Key Modules: {'\n'}
            <ListItemButton>
              <ListItemText>{`\u2022`} Trade and Investment</ListItemText>
            </ListItemButton>
            <ListItemButton>
              <ListItemText>{`\u2022`} International Strategic Innovation Management</ListItemText>
            </ListItemButton>
            <ListItemButton>
              <ListItemText>{`\u2022`} Trade and Investment</ListItemText>
            </ListItemButton>
          </Typography>
          
        </Grid>
        
        
        <Grid item xs={12} sm={6} md={4} padding={4}>
        <Box display="flex" alignItems="center">
          <img src={Glyph} alt="Icon 1" style={iconStyle} />
          
          <Typography variant="h6" paddingLeft={2} sx={{ fontWeight: 'bold' }}>
            Leadership Experience
          </Typography>
        </Box>
          
          <Typography paragraph sx={{ fontWeight: 'bold' }} textAlign={'left'}>
            Boinc Gaming Ltd
          <Typography caption sx={{ fontWeight: 'light' }} textAlign={'left'}>
            Founder/Director (2020 - Present)
          </Typography>
          </Typography>
        </Grid>
        
        <Grid item xs={12} sm={6} md={4} padding={4}>
        <Box display="flex" alignItems="center">
          <img src={Glyph} alt="Icon 1" style={iconStyle} />
          
          <Typography variant="h6" paddingLeft={2} sx={{ fontWeight: 'bold' }}>
            Proficiencies
          </Typography>
        </Box>
          <Typography paragraph sx={{ fontWeight: 'bold' }} textAlign={'left'} marginBottom={0}>
            Skills
          </Typography>
          <ListItemButton>
              <ListItemText>{`\u2022`} Microsoft Office Proficiency (Word, Excel, Powerpoint)</ListItemText>
            </ListItemButton>
            <ListItemButton>
              <ListItemText>{`\u2022`} Strong problem-solving and decision-making skills</ListItemText>
            </ListItemButton>
            <ListItemButton>
              <ListItemText>{`\u2022`} Excellent communication and interpersonal skills</ListItemText>
            </ListItemButton>
            <ListItemButton>
              <ListItemText>{`\u2022`} Experience in financial analysis and reporting</ListItemText>
            </ListItemButton>
            <ListItemButton>
              <ListItemText>{`\u2022`} Experience in Business Development and Market Research</ListItemText>
            </ListItemButton>
            <ListItemButton>
              <ListItemText>{`\u2022`} Leadership & Team Management skills</ListItemText>
            </ListItemButton>
        </Grid>
        {/* Repeat for other icons and features */}
      </Grid>
    </Box>
  );
};

export default AboutSection;
