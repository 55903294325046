import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    common: {
      white: '#FFFFFF', // White color
    },
    background: {
      default: './src/image/BGimage.png', // Background color for the application
    },
    primary: {
      main: '#3498DB', // Primary color used across the application
    },
    secondary: {
      main: '#27AE60', // Secondary color for your application
      light: '#27AE601F', // Light variant of the secondary color (with opacity)
    },
    success: {
      main: '#2ECC71', // Color used to indicate success
      dark: '#27AE60', // Dark variant of the success color
    },
  },
});

export default theme;
