import React from 'react';
import { AppBar, Toolbar, Button, Box, useScrollTrigger, Slide } from '@mui/material';
import { Link } from 'react-scroll';
import theme from '../theme'; // Adjust the import path to your theme file as necessary
import Logo from '../Logos/Logo.png'

function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={true} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const NavbarComponent = (props) => {
  const pillButtonStyle = {
    margin: '0 8px',
    borderRadius: '20px', // Pill shape
    backgroundColor: theme.palette.secondary.main, // Green color from your theme for the "Contact" button
    color: theme.palette.common.white, // White text color
    textTransform: 'none', // Prevents uppercase transformation
  };

  const regularButtonStyle = {
    margin: '0 8px',
    textTransform: 'none', // Prevents uppercase transformation
  };

  return (
    <HideOnScroll {...props}>
      <AppBar position="fixed" style={{ background: theme.palette.background.default, boxShadow: 'none' }}>
        <Toolbar>
        <Box display="flex" flexGrow={1} alignItems="center">
  {/* Replace with your logo path */}
  <img src={Logo} alt="logo" />
</Box>

{['About', 'Services', 'Portfolio'].map((item) => (
  <Link
    key={item}
    to={item.toLowerCase()}
    spy={true}
    smooth={true}
    duration={500}
    style={{ textDecoration: 'none' }}
  >
    <Button style={{ ...regularButtonStyle, color: 'white' }}>
      {item}
    </Button>
  </Link>
))}
          <Link 
            to="contact" 
            spy={true} 
            smooth={true} 
            duration={500}
            style={{ textDecoration: 'none' }}
          >
            <Button 
              variant="contained"
              style={pillButtonStyle}
            >
              Contact
            </Button>
          </Link>
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );
};

export default NavbarComponent;
