import React, { useState } from 'react';
import { Box, Typography, Grid, List, ListItem, ListItemText, Paper, useTheme } from '@mui/material';
import theme from '../theme.js';
import PortfolioGlyph from '../Icons/Portfolio Icon.png'

// Replace with your actual portfolioCategories data.
const portfolioCategories = [
  // ... your portfolio categories data
  {
    category: "Operations Manager",
    projects: [
      {
        title: "Operations Manager",
        company: "Boinc Gaming Limited",
        details: [
          "\u2022 Manage day-to-day activities of the business to ensure the business is operating with minimal interruptions.",
          "\u2022 Lead business development efforts, seeking new partnerships and sponsorships.",
          "\u2022 Handle marketing activities to promote the company and its services.",
          "\u2022 Organise and coordinate events to enhance brand visibility and engagement.",
          // More details here
        ],
      },
      // More projects for Category 1
    ],
  },
  {
    category: "Logistics Operations Manager",
    projects: [
      {
        title: "Logistics Operations Manager",
        company: "InTwice",
        details: [
          "\u2022 Managed rail transportation, negotiated rate agreements, and signed contracts.",
          "\u2022 Utilised Microsoft Excel, TM Maps, and AC client for logistics operations.",
          // More details here
        ],
      },
    ],
  },
  {
    category: "Country Director",
    projects: [
      {
        title: "Country Director",
        company: "FUMIX",
        details: [
          "\u2022 Developed a business plan and established a supply chain in Poland.",
          "\u2022 Utilised Microsoft Excel, PowerPoint, and Affinity Design for business operations.",
          // More details here
        ],
      },
    ],
  },
]

const iconStyle = {
  height: '50px', // Adjust the size as needed
  marginBottom: theme.spacing(1),
};

const PortfolioComponent = () => {
  const theme = useTheme();

  // Check if the portfolioCategories array is not empty before setting the initial state.
  const [activeCategory, setActiveCategory] = useState(portfolioCategories.length > 0 ? portfolioCategories[0] : null);

  const handleListItemClick = (category) => {
    setActiveCategory(category);
  };

  return (
    <Box
      id="portfolio"
      sx={{
        py: theme.spacing(8),
        px: theme.spacing(4),
        backgroundColor: theme.palette.background.default,
        color: theme.palette.common.white,
        minHeight: '50vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflow: 'hidden',
      }}
    >
      <Typography variant="h3" gutterBottom align="center">
        Portfolio
      </Typography>
      <Typography variant="h5" gutterBottom align="center" style={{marginBottom: '30px'}}>
        Take a look at my projects
      </Typography>
      <Grid container spacing={4} alignItems="stretch" style={{ height: 'calc(100% - 48px)' }}>
        <Grid item xs={12} sm={4}>
          <List component="nav" sx={{ bgcolor: 'transparent' }}>
            {portfolioCategories.map((category, index) => (
              <ListItem
                button
                key={index}
                selected={activeCategory && activeCategory.category === category.category}
                onClick={() => handleListItemClick(category)}
                sx={{
                  bgcolor: activeCategory && activeCategory.category === category.category ? 'success.main' : 'transparent',
                  '&:hover': {
                    bgcolor: 'success.light',
                  },
                  '&.Mui-selected': {
                    bgcolor: 'success.main',
                  },
                  '& .MuiListItemText-primary': {
                    color: activeCategory && activeCategory.category === category.category ? 'theme.palette.success.main' : 'theme.palette.common.white',
                  },
                }}
              >
                <ListItemText primary={category.category} />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Box sx={{ overflowY: 'auto', height: '100%' }}>
            {/* Only attempt to map over projects if activeCategory is defined */}
            {activeCategory && activeCategory.projects.map((project, index) => (
              <Paper
                key={index}
                elevation={3}
                sx={{
                  p: theme.spacing(2),
                  mb: theme.spacing(2),
                  bgcolor: 'rgba(255, 255, 255, 0.1)',
                  backdropFilter: 'blur(10px)',
                  border: '1px solid rgba(255, 255, 255, 0.3)',
                  borderRadius: theme.shape.borderRadius,
                  color: 'common.white',
                }}
              >
                <Typography variant="h6">{project.title}</Typography>
                <Typography variant="subtitle1">{project.company}</Typography>
                {project.details.map((detail, detailIndex) => (
                  <Typography key={detailIndex} variant="body2">
                    {detail}
                  </Typography>
                ))}
              </Paper>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PortfolioComponent;
