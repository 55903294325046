import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { Link } from 'react-scroll';
import theme from '../theme'; // Ensure this path is correct

const HeroSection = () => {
  const heroStyle = {
    height: '100vh', // Makes the hero section take up the full viewport height
    color: theme.palette.common.white,
    backgroundColor: theme.palette.background.default, // Use the theme background color
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: theme.spacing(4), // Use theme spacing for consistent padding
  };

  const ctaButtonStyle = {
    marginTop: theme.spacing(2),
    borderRadius: '20px',
    backgroundColor: theme.palette.secondary.main, // Use the theme primary color
    color: theme.palette.common.white,
    padding: theme.spacing(1, 4),
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.light, // Darken the button on hover
    },
  };
  
  return (
    <Box style={heroStyle} id="hero">
      <Typography variant="h2" gutterBottom>
        Hey there, I’m Vladyslav Yeromin
      </Typography>
      <Typography variant="h5" gutterBottom>
        The brains behind VYERO Solutions
      </Typography>
      <Typography paragraph maxWidth={950}>
      The brains behind VYERO Solutions.
My mission is to harness today’s cutting-edge technologies for your business needs. Embracing these innovations saves time, money, and prevents errors often associated with human nature. My focus is to optimize and automate your daily operations, freeing up your time for strategic planning and high-stakes negotiations.
      </Typography>
      <Link to="contact" spy={true} smooth={true} duration={500}>
        <Button variant="contained" style={ctaButtonStyle}>
          Contact Me
        </Button>
      </Link>
    </Box>
  );
};

export default HeroSection;
